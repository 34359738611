export const themes = {
  white: {
    menubar: "#fff",
    mainColor: "#222",
    bgPage: "#f0f2f5",
    contentPage: "#fff",
    borders: "#e8e8e8",
    avaliations: "#F4F4F4",
    rate: "#525252",
    shadow: "#e7e7e7",
    bgInput: "#fff",
    labelInput: "#222",
    placeholders: "#acacac",
    poweredBy: "#7e7e7e",
    bgRateColor: "rgba(0, 0, 0, 0.06)",
    principal: "#1677ff",
    disabled: "#e9e9e9",
  },
  dark: {
    menubar: "#222",
    mainColor: "#fff",
    bgPage: "#373737",
    contentPage: "#222",
    borders: "#525252",
    avaliations: "#373737",
    rate: "#F4F4F4",
    shadow: "#2e2e2e",
    bgInput: "#222",
    labelInput: "#fff",
    placeholders: "#525252",
    poweredBy: "#7e7e7e",
    bgRateColor: "rgba(255, 255, 255, 0.3)",
    principal: "#1677ff",
    disabled: "#707070",
  },
};

export const themeDefaultFront = {
  menubar: "#fff",
};
