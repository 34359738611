import React, {
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";

import { message } from "antd";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { authSuccess, logoutUser } from "./reducers/accountReducer";

import { getStorage, setStorage, removeStorage } from "./utils/storage";

import languages from "./utils/languages";
import { getConfigLoginAccount } from "./services/account";
import renameSite from "./utils/renameSite";
import updateFavicon from "./utils/updateFavicon";

const ContextGlobal = createContext();

const createTimestampWith12Hours = () => {
  const now = moment();
  const futureTimestamp = now.add(12, "hours");
  return futureTimestamp;
};

const initialState = {
  logged: false,
  currentLanguage: null,
};

const ContextGlobalProvider = memo(({ children }) => {
  const [state, setState] = useState(initialState);
  const [showUniversalMessage, universalMessage] = message.useMessage();
  const dispatch = useDispatch();

  const { accountconfig } = useSelector((state) => state);

  useLayoutEffect(() => {
    setState((prevState) => ({
      ...prevState,
      currentLanguage:
        navigator.language === "pt-BR" ? languages.ptBr : languages.enUs,
    }));
  }, []);

  useEffect(() => {
    const token = getStorage("token");
    const account = getStorage("account");

    if (token && account) {
      const payload = {
        data: {
          token,
          account: account.account,
          user: account.user,
          student: account.student,
        },
      };
      dispatch(authSuccess(payload));
      return setState((prevState) => ({ ...prevState, logged: true }));
    }
    setState((prevState) => ({ ...prevState, logged: false }));
  }, [dispatch]);

  useLayoutEffect(() => {
    const professionals = window.location.pathname.split("/");
    const getToken =
      getStorage("token_client") ||
      professionals[2] ||
      (getStorage("account") && getStorage("account").account.token);
    if (getToken) {
      dispatch(getConfigLoginAccount(getToken));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      removeStorage("token_client");
    };
  }, []);

  useEffect(() => {
    if (
      accountconfig &&
      accountconfig.account &&
      accountconfig.account.titlePages
    )
      renameSite(accountconfig.account.titlePages);
    if (
      accountconfig &&
      accountconfig.account &&
      accountconfig.account.pageIcon
    )
      updateFavicon(accountconfig.account.pageIcon);
  }, [accountconfig]);

  const preserveSession = useCallback((data) => {
    setStorage("timestamp", createTimestampWith12Hours());
    setStorage("token", data.token);
    setStorage("account", {
      account: data.account,
      user: data.user,
      student: data.student || null,
    });
    setState((prevState) => ({ ...prevState, logged: true }));
  }, []);

  const logout = useCallback(() => {
    setState((prevState) => ({ ...prevState, logged: false }));
    dispatch(logoutUser());
    removeStorage("token");
    removeStorage("account");
    removeStorage("timestamp");
  }, [dispatch]);

  const setLanguage = useCallback((lang) => {
    setState((prevState) => ({
      ...prevState,
      currentLanguage: lang === "pt-BR" ? languages.ptBr : languages.enUs,
    }));
  }, []);

  const providerValues = useMemo(() => {
    return {
      state,
      actions: {
        showUniversalMessage,
        preserveSession,
        logout,
        setLanguage,
      },
    };
  }, [state, showUniversalMessage, preserveSession, logout, setLanguage]);

  return (
    <ContextGlobal.Provider value={providerValues}>
      {universalMessage}
      {children}
    </ContextGlobal.Provider>
  );
});

const useContextGlobal = () => useContext(ContextGlobal);

export { useContextGlobal };

export default ContextGlobalProvider;
