import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: null,
};

export const plansReducer = createSlice({
  name: "plans",
  initialState,
  reducers: {
    plansRequest: (state) => {
      state.loading = true;
    },
    planSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    plansError: (state) => {
      state.loading = false;
    },
    changePlanSuccess: (state) => {
      state.loading = false;
    },
  },
});

export const { plansRequest, planSuccess, plansError, changePlanSuccess } =
  plansReducer.actions;

export default plansReducer.reducer;
