function setStorage(item, value) {
  return localStorage.setItem(`makeitplace_${item}`, JSON.stringify(value));
}

function getStorage(item) {
  const itemStorage = localStorage.getItem(`makeitplace_${item}`);

  return JSON.parse(itemStorage);
}

function removeStorage(item) {
  localStorage.removeItem(`makeitplace_${item}`);
}

export { setStorage, getStorage, removeStorage };
