import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {},
};

export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardRequest: (state) => {
      state.loading = true;
    },
    dashboardSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    dashboardError: (state) => {
      state.loading = false;
    },
  },
});

export const { dashboardRequest, dashboardSuccess, dashboardError } =
  dashboardReducer.actions;

export default dashboardReducer.reducer;
