import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {},
};

export const portfolioReducer = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    portfolioRequest: (state) => {
      state.loading = true;
    },
    portfolioSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    portfolioError: (state) => {
      state.loading = false;
    },
    portfolioNoLoad: (state) => {
      state.loading = false;
    },
  },
});

export const { portfolioRequest, portfolioSuccess, portfolioError, portfolioNoLoad } =
  portfolioReducer.actions;

export default portfolioReducer.reducer;
