import { getStorage } from "./storage";

export function postFetch(payload, method) {
  const token = getStorage("token");
  const options = {
    method: method ? method : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "private, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: 0,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  return options;
}

export function getFetch() {
  const token = getStorage("token");
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}
//dev
// export const baseUrl = "http://localhost:4000/v1";
export const baseUrl = "https://maketplace-api-production.up.railway.app/v1";
